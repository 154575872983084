// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-projects-car-daddy-index-js": () => import("./../../../src/pages/projects/car-daddy/index.js" /* webpackChunkName: "component---src-pages-projects-car-daddy-index-js" */),
  "component---src-pages-projects-colorcomb-index-js": () => import("./../../../src/pages/projects/colorcomb/index.js" /* webpackChunkName: "component---src-pages-projects-colorcomb-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-project-turnip-index-js": () => import("./../../../src/pages/projects/project-turnip/index.js" /* webpackChunkName: "component---src-pages-projects-project-turnip-index-js" */),
  "component---src-pages-projects-wildlife-reserve-index-js": () => import("./../../../src/pages/projects/wildlife-reserve/index.js" /* webpackChunkName: "component---src-pages-projects-wildlife-reserve-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

