import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2bbfa7",
    },
    secondary: {
      main: "#302e43",
    },
  },
  typography: {

    primary: {
      
      fontFamily: [
          'Poppins',
          'Roboto',
          'sans-serif'
        ].join(','),
      },

    },

  });

export default theme;